export const SET_CURRENT_SONG = 'SET_CURRENT_SONG'
export const SET_CURRENT_AMBIENCE = 'SET_CURRENT_AMBIENCE'

export const SET_POLYGONMASK = 'SET_POLYGONMASK'
export const SHOW_POLYGON = 'SHOW_POLYGON'
export const HIDE_POLYGON = 'HIDE_POLYGON'


export const TOGGLE_RANDOM = 'TOGGLE_RANDOM'
export const TOGGLE_REPEAT = 'TOGGLE_OPTIONS'
export const TOGGLE_PLAYING = 'TOGGLE_PLAYING'
export const TOGGLE_AMBIENCE_PLAYING = 'TOGGLE_AMBIENCE_PLAYING'
export const TOGGLE_AMBIENCE_AUDIO_GLOBAL = 'TOGGLE_AMBIENCE_AUDIO_GLOBAL'

export const SET_CLICKED = 'SET_CLICKED'


