export const ambienceArr = [

  [
    "rain",
    "https://ambiences.s3-us-west-2.amazonaws.com/rain.mp3",
  ],
  [
    "fireplace",
    "https://ambiences.s3-us-west-2.amazonaws.com/fireplace.mp3",
  ],
  [
    "ocean",
    "https://ambiences.s3-us-west-2.amazonaws.com/ocean1.mp3",
  ],
  [
    "rain",
    "https://ambiences.s3-us-west-2.amazonaws.com/rain.mp3",
  ],
  [
    "fireplace",
    "https://ambiences.s3-us-west-2.amazonaws.com/fireplace.mp3",
  ],
  [
    "ocean",
    "https://ambiences.s3-us-west-2.amazonaws.com/ocean1.mp3",
  ],
];