import React from 'react'
import "./../../styles/dim.css"

function DimOverlay(props) {

  return (
        <div className={props.dim}/> 
    )
}

export default DimOverlay