import React from 'react'

function Title() {
  return (
    <div className = "title" >
      <header className = "header">
        [ramenshop radio]
      </header>
    </div>
  )
}

export default Title
